import React from "react"
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
  Grid,
  CardActionArea,
  Link,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import moment from "moment"
import _ from "lodash"
import { navigate } from "gatsby"

import theme from "../theme"

const useStyles = makeStyles(theme => ({
  cardHeight: {
    height: "100%",
  },
  cursorPointer: {
    cursor: "pointer",
  },
}))

const ArticleCardComponent = props => {
  const classes = useStyles(theme)

  return (
    <>
      {props.horizontal ? (
        <Link
          component={Grid}
          underline="none"
          onClick={() => navigate(`/${props.node.slug}`)}
          container
          spacing={3}
          direction="row"
          className={classes.cursorPointer}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
              component={Card}
              className={classes.cardHeight}
            >
              <Grid item xs={12} md={4}>
                <CardMedia
                  component="img"
                  image={props.node.picture.publicURL}
                  title={props.node.picture.name}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <CardContent>
                  <Typography gutterBottom variant="body1" component="h3">
                    {_.truncate(props.node.title, { length: 100 })}
                  </Typography>
                  <Box display="flex" flexDirection="row">
                    <Typography variant="caption" component="p">
                      {moment(props.node.createdAt).format("ll")}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      color="textSecondary"
                    >
                      . {props.node.category && props.node.category.name} .
                    </Typography>
                    <Typography variant="caption" component="p">
                      3 min read
                    </Typography>
                  </Box>
                </CardContent>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      ) : (
        <Card className={classes.cardHeight}>
          <Link
            component={CardActionArea}
            color="textPrimary"
            underline="none"
            onClick={() => navigate(`/${props.node.slug}`)}
          >
            <CardMedia
              component="img"
              image={props.node.picture.publicURL}
              title={props.node.picture.name}
            />
            <CardContent>
              <Typography gutterBottom variant="body1" component="h3">
                {_.truncate(props.node.title, { length: 100 })}
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                pb={props.excerpt ? 1 : 0}
              >
                <Typography variant="caption" component="p">
                  {moment(props.node.createdAt).format("ll")}
                </Typography>
                <Typography
                  variant="caption"
                  component="p"
                  color="textSecondary"
                >
                  . {props.node.category && props.node.category.name} .
                </Typography>
                <Typography variant="caption" component="p">
                  3 min read
                </Typography>
              </Box>
              {props.excerpt && <Typography>{props.node.excerpt}</Typography>}
            </CardContent>
          </Link>
          <Box display="flex" flexWrap="wrap" p={1}>
            {_.map(props.node.tags, (tag, i) => {
              return (
                <Button size="small" color="primary" key={i}>
                  {tag.name}
                </Button>
              )
            })}
          </Box>
        </Card>
      )}
    </>
  )
}

export default ArticleCardComponent
